import {React, useState} from "react";
import { Helmet } from "react-helmet";
import axios from 'axios';

const EmailCollection = () => {
    const [email, setEmail] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitted email:', email);
        setEmail('');
        try {
            const response = await axios.post('https://us-central1-manikin-a60d0.cloudfunctions.net/submitEmail', { email });
            setMessage('Email submitted successfully');
            setEmail('');
            console.log(response);
        } catch (error) {
            setMessage('Error submitting email');
            console.error('Error submitting email:', error);
        }
        setSubmitting(false);
    };

  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Email Collection sign up for updates || Nail manikin visualize your nail designs</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="interest-form d-lg-flex align-items-center">
      <div className="order-lg-last interest-page-img-holder">
      <img
        src="img/launch-hero-img-2.png"
        alt="media"
        style={{ objectFit: 'cover' }} 
      />
    </div>
      <div className="text-wrapper order-md-first font-rubik" >
            <h3 className="font-slab">Design Your Nails with Nail Manikin</h3>
            <p>Create, customize and preview nail designs online</p>
            <p>Be the first to know when we launch</p>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" >Email: &nbsp;</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ marginBottom: '20px',
                        outline: '2px solid #BBDEFB'}}/>
                <button 
                  type="submit" 
                  className="theme-btn-two" 
                  disabled={submitting}
                  style={{ marginTop: '20px' }}>
                  {submitting ? 'Submitting...' : 'Submit'}
                </button>
                {message && <p>{message}</p>}
            </form>
        </div>
      </div>
    </div>
  );
};

export default EmailCollection;
