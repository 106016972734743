import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/ScrollToTop";
import AllRoutes from "./router/AllRoutes"
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
  <Helmet>
    <title>Nail Manikin - Unleash Your Creativity</title>
    <meta property="og:site_name" content="Nail Manikin" />
    <meta property="og:url" content="https://www.nailmanikin.com" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Nail Manikin: Innovative Nail Art Configurator" />
    <meta 
      name="keywords" 
      content="nail art, nail design, 3D nail art, nail customization, beauty, fashion, creative nail solutions, DIY nail design, interactive nail design, nail art application"
    />
    <meta 
      name="description" 
      content="Nail Manikin is the ultimate platform for creative nail art design. Our interactive 3D configurator lets you visualize and design custom nail art, bringing your ideas to life."
    />
  </Helmet>

  <ScrollToTop />

  <AllRoutes />
  </>
  );
};

export default App;
